//#START CUSTO-SAMVAZ
import { CompiereDataGridFilterType, CompiereDataGridType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
export interface SalesRep {
  XX_CMSN_Calculated_ID: number;
  name: string;
}

export function getFormID() {
  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      startRow: 0,
      endRow: 1,
      tableName: `(select ad_form_id from ad_form where angularClass like '%PrintZoneComponent%') as Forms`,
      filterModel: {}
    }
  };
}
export function getCalloutFieldIds(ad_tab_id: number, columnNames: string[]) {
  const columnNamesString = "'" + columnNames.join("','") + "'";
  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      startRow: 0,
      endRow: -1,
      tableName: `( select c.columnName,f.ad_field_id from ad_field f inner join ad_column c on f.ad_column_id = c.ad_column_id where f.ad_tab_id=${ad_tab_id} and c.columnName in (${columnNamesString})) as CalloutFields`,
      filterModel: {}
    }
  };
}
export function getTabDataRequest() {
  return {
    windowId: null,
    parent_constraint: '',
    compiereRequest: {
      startRow: 0,
      endRow: -1,
      tableName: `( select tv.tableName,tv.ad_tab_id,tv.ad_table_id,tv.ad_window_id,tv.taggedcolumns,tv.docfilters,w.value FROM ad_tab_v tv INNER JOIN ad_window w on tv.ad_window_id=w.ad_window_id WHERE (tv.tablename='C_Order' AND w.value='Sales Order_143') OR (tv.tablename='XX_CMSN_Calculated' AND w.value='Sales Order_143') OR (tv.tablename='C_OrderLine' AND w.value='Sales Order_143') OR (tv.tablename='C_OrderTax' AND w.value='Sales Order_143')) as TabRequest`,
      filterModel: {}
    }
  };
}
export function getOrderTaxRequest(c_order_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_OrderTax',
      ad_language,
      filterModel: {
        C_Order_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_order_id]
        }
      }
    }
  };
}
export function getOrderRequest(c_order_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Order',
      ad_language,
      filterModel: {
        C_Order_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_order_id]
        }
      }
    }
  };
}
export function getOrderLineRequest(c_order_id: number, ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_OrderLine',
      ad_language,
      filterModel: {
        C_Order_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_order_id]
        }
      }
    }
  };
}

export function getOrderPORefRequest(c_bpartner_id: number, po_ref: string, c_order_id: number) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Order',
      filterModel: {
        C_BPartner_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_bpartner_id]
        },
        POReference: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [po_ref]
        },
        C_Order_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.NOT_EQUALS],
          values: [c_order_id ? c_order_id : 0]
        }
      }
    }
  };
}

export function getOrderPORefRequestDuplicateBP(c_bpartner_id: number, po_ref: string, c_order_id: number, c_doctype_id: number) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Order',
      filterModel: {
        C_BPartner_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_bpartner_id]
        },
        POReference: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [po_ref]
        },
        C_Order_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.NOT_EQUALS],
          values: [c_order_id ? c_order_id : 0]
        },
        C_DocType_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_doctype_id]
        }
      }
    }
  };
}

export function getOrderPORefRequestDuplicateDT(po_ref: string, c_order_id: number, c_doctype_id: number) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'C_Order',
      filterModel: {
        POReference: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [po_ref]
        },
        C_Order_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.NOT_EQUALS],
          values: [c_order_id ? c_order_id : 0]
        },
        C_DocType_ID: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.EQUALS],
          values: [c_doctype_id]
        }
      }
    }
  };
}

export function getLogMessageRequest(ad_language: string) {
  return {
    windowId: -1,
    parent_constraint: undefined,
    compiereRequest: {
      windowType: CompiereDataGridType.TABLE,
      tableName: 'AD_MESSAGE',
      ad_language,
      filterModel: {
        value: {
          filterType: CompiereDataGridFilterType.SET,
          operators: [OperatorFilterType.CONTAINS],
          values: ['SZ_FastOrder']
        }
      }
    }
  };
}

export function getPartnerRequest(c_bpartner_id: Number) {
  return {
    windowId: null,
    parent_constraint: undefined,
    compiereRequest: {
      startRow: 0,
      endRow: 1,
      tableName: `( select c.XX_GeneralComment FROM C_BPartner c WHERE c.C_BPartner_ID = ${c_bpartner_id}) as C_BPartner`,
      filterModel: {}
    }
  };
}
export function getSalesRepRequest(c_order_id: number, ad_language: string) {
  return {
    windowId: null,
    parent_constraint: undefined,
    compiereRequest: {
      startRow: 0,
      endRow: -1,
      tableName: `( select u.name,xx.XX_CMSN_CALCULATED_ID FROM XX_CMSN_Calculated xx INNER JOIN AD_User u ON xx.salesrep_id = u.AD_User_ID WHERE xx.C_Order_ID = ${c_order_id}) as SalesRep`,
      filterModel: {}
    }
  };
}
export function getTaxOrderGroupRequest(c_order_id: number, tabId: number): DataStoreRequest {
  return {
    windowId: null,
    parent_constraint: null,
    compiereRequest: {
      windowType: CompiereDataGridType.WINDOW,
      entityId: tabId,
      startRow: 0,
      endRow: -1,
      rowGroupCols: [{ id: 'C_Tax_ID', displayName: 'TVA', field: 'C_Tax_ID', aggFunc: null }],
      valueCols: [{ id: 'TaxAmt', colId: 'TaxAmt', aggFunc: 'sum', displayName: 'Montant TVA', field: 'TaxAmt' }],
      groupKeys: [],
      filterModel: {
        C_Order_ID: { filterType: CompiereDataGridFilterType.SET, values: [c_order_id], operators: [OperatorFilterType.EQUALS] }
      },
      sortModel: []
    }
  };
}
//#endregion DATAGRID REQUEST

export function getNullValueInData(data: any, columnNames: string[]) {
  let nullValue = null;
  columnNames.forEach((columnName) => {
    const value = data[columnName];
    if (
      value == null ||
      value == undefined ||
      value == '' ||
      (value.trim && value.trim() == '') ||
      (columnName === 'AD_Org_ID' && value.id <= 0)
    ) {
      nullValue = columnName;
      return;
    }
  });
  return nullValue;
}

export function getLineCustomDesignItems() {
  return [
    {
      vcr: 'vcrsLines',
      type: CustomDesignItemType.FIELD,
      columnName: 'Line',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsIsDescription',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsDescription',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsCharge',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Charge_ID',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsProduct',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_Product_ID',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsXX_RelatedProduct',
      type: CustomDesignItemType.FIELD,
      columnName: 'isRelatedProduct',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsXX_RelatedProduct',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_RelatedProduct',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsDescription',
      type: CustomDesignItemType.FIELD,
      columnName: 'Description',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsUOM',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_UOM_ID',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsQty',
      type: CustomDesignItemType.FIELD,
      columnName: 'QtyEntered',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsPriceActual',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceActual',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsPriceList',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceList',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsXX_IsNetPrice',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_IsNetPrice',
      cssClass: 'XX_isnetprice no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsXX_QtyUnit',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_QtyUnit',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsXX_UOM_ID',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_UOM_ID',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsDiscount',
      type: CustomDesignItemType.FIELD,
      columnName: 'Discount',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsPriceEntered',
      type: CustomDesignItemType.FIELD,
      columnName: 'PriceEntered',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsLineTotalAmt',
      type: CustomDesignItemType.FIELD,
      columnName: 'LineNetAmt',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    // {
    //   vcr: 'vcrsC_Tax_ID',
    //   type: CustomDesignItemType.FIELD,
    //   columnName: 'C_Tax_ID',
    //   cssClass: 'no-class',
    //   isLabelDisplay: false
    // },
    {
      vcr: 'vcrsXX_TrialPhaseDays',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_TrialPhaseDays',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsXX_GroupLine',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_GroupLine',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsXX_IsComplexPrice',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_IsComplexPrice',
      cssClass: 'no-class',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrsLocator',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_Locator_ID',
      cssClass: 'no-class',
      isLabelDisplay: false
    }
  ];
}
export function getHeaderCustomDesignItems() {
  return [
    {
      vcr: 'vcrMainButtons',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_SalesRepSelection',
      cssClass: 'p-lg-12 p-md-12 p-col-9',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrLastMainButton',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_FeesDiscounts',
      cssClass: 'p-lg-12 p-md-12 p-col-9',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrMainButtons',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_SelectionMode',
      cssClass: 'p-lg-12 p-md-12 p-col-9',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrLineButtons',
      type: CustomDesignItemType.FIELD,
      columnName: 'CopyFrom',
      cssClass: 'p-lg-2 p-sm-6 p-md-6 p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrLineButtons',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_Managelines',
      cssClass: 'p-lg-2 p-sm-6 p-md-6 p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDocType',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_DocTypeTarget_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBPartner',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_BPartner_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrConstSiteAddress',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_ConstSiteAddress_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBillLocation',
      type: CustomDesignItemType.FIELD,
      columnName: 'Bill_Location_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBPartnerLocation',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_BPartner_Location_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrTechnician',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_Technician_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrFitter',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_Fitter_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDescription',
      type: CustomDesignItemType.FIELD,
      columnName: 'Description',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrMWarehouse',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_Warehouse_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrAdUser',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_User_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrConstSiteUser',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_ConstSiteUser_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrBillUser',
      type: CustomDesignItemType.FIELD,
      columnName: 'Bill_User_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDeliveryViaRule',
      type: CustomDesignItemType.FIELD,
      columnName: 'DeliveryViaRule',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrMShipper',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_Shipper_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrOrder',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_Order_ID',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrPOReference',
      type: CustomDesignItemType.FIELD,
      columnName: 'POReference',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDateOrdered',
      type: CustomDesignItemType.FIELD,
      columnName: 'DateOrdered',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrDatePromised',
      type: CustomDesignItemType.FIELD,
      columnName: 'DatePromised',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrComment',
      type: CustomDesignItemType.FIELD,
      columnName: 'XX_Comment',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrWeight',
      type: CustomDesignItemType.FIELD,
      columnName: 'Weight',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrGrandTotal',
      type: CustomDesignItemType.FIELD,
      columnName: 'GrandTotal',
      cssClass: 'p-col-12',
      isLabelDisplay: false
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsSOTrx',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsReturnTrx',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'AD_Org_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_DocType_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DocAction',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'InvoiceRule',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_PriceList_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'M_DiscountSchema_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'DeliveryRule',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'IsReleaseDocument',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'isQuote',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'PaymentRule',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'C_PaymentTerm_ID',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrSettings',
      type: CustomDesignItemType.FIELD,
      columnName: 'OrderType',
      cssClass: 'p-col-12'
    },
    {
      vcr: 'vcrProcessOrder',
      type: CustomDesignItemType.FIELD,
      columnName: 'Processing',
      cssClass: 'p-col-12'
    }
  ];
}
